import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  ChakraComponent,
  ComponentWithAs,
  createStandaloneToast,
  Flex,
  FlexProps,
  HStack,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  OrderedList,
  Spinner,
  StackProps,
  Text,
  TextProps,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { theme as baseTheme } from '@chakra-ui/theme';
import { useRouter } from 'next/router';
import Image from 'next/image';
import React, {
  ElementType,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PopupModal } from 'react-calendly';
import { Calendar, Check, Copy } from 'react-feather';
import type { ContractState } from '../utils/account/account-types';
import { capitalizeFirstLetter } from '../utils/string-utils';
import {
  CompleteIcon,
  ExclamationIcon,
  ExclamationIconLarge,
  ViewPricingIcon,
  WarningIcon,
} from './icons-library';
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from './pagination';
import { Limits } from './pagination/lib/hooks/usePagination';
import { useAccountEmail } from '../utils/msal';

import styles from './common.module.css';

// Define common types

export type TitleProps = {
  title: string;
  description?: string;
};

export type IconAndButtonProps = {
  icon: React.ReactNode;
  buttonLabel: string;
};

export type BaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

// Define components

type UsageInfoBannerProps = {
  text: string;
  centered?: boolean;
};

export const UsageInfoBanner = ({
  text,
  centered = false,
  ...props
}: UsageInfoBannerProps & BoxProps) => (
  <Flex
    width='100%'
    bg='smBlue.150'
    p='0.6em'
    rounded='xl'
    {...props}
    justifyContent={centered ? 'center' : ''}
  >
    <Flex alignItems='center'>
      <Calendar size={20} />
    </Flex>
    <Text
      width={centered ? '' : '100%'}
      color='smBlack.400'
      fontFamily='var(--default-font-family)'
      fontSize='0.8em'
      ml='1em'
    >
      {text}
    </Text>
  </Flex>
);

export interface WarningBannerProps extends FlexProps {
  centered?: boolean;
  dataQa?: string;
  text?: string;
}

export const WarningBanner: FunctionComponent<WarningBannerProps> = ({
  text = null,
  centered = false,
  ...props
}) => (
  <Flex
    rounded='xl'
    width='100%'
    bg='smOrange.200'
    p='0.6em'
    {...props}
    justifyContent={centered ? 'center' : ''}
  >
    <Flex alignItems='center'>
      <WarningIcon width='1.5em' height='1.5em' />
    </Flex>
    {props.children ? (
      <Box justifyContent={centered ? 'center' : ''} color='smBlack.400' ml='1em'>
        {props.children}
      </Box>
    ) : (
      <Text
        width={centered ? '' : '100%'}
        color='smBlack.400'
        fontFamily='var(--default-font-family)'
        fontSize='0.9em'
        ml='1em'
      >
        {text}
      </Text>
    )}
  </Flex>
);

export interface SuccessBannerProps {
  text?: ReactElement;
  content?: ReactElement;
  centered?: boolean;
}
export const SuccessBanner: FunctionComponent<SuccessBannerProps> = ({
  text = null,
  content = null,
  centered = false,
  ...props
}) => (
  <Flex
    width='100%'
    bg='smGreen.200'
    rounded='xl'
    p='0.5em'
    {...props}
    justifyContent={centered ? 'center' : ''}
  >
    <Flex alignItems='center'>
      <CompleteIcon width='1.5em' height='1.5em' />
    </Flex>
    {content ? (
      <Box justifyContent={centered ? 'center' : ''} color='smBlack.400' ml='1em'>
        {content}
      </Box>
    ) : (
      <Text
        width={centered ? '' : '100%'}
        color='smBlack.400'
        fontFamily='var(--default-font-family)'
        fontSize='1em'
        ml='1em'
      >
        {text}
      </Text>
    )}
  </Flex>
);

export const NoSomethingBanner: React.FunctionComponent = (props) => (
  <Flex rounded='xl' width='100%' justifyContent='center'>
    <ExclamationIcon />
    <Text ml='1em'>{props.children}</Text>
  </Flex>
);

export interface InfoBarboxProps extends StackProps {
  bgColor?: string;
  icon: ReactNode;
  title: string;
  description: string;
  buttonLabel: string;
  hrefUrl?: string;
  setStateUp?: () => void;
  buttonOnClick?: () => void;
}
export const InfoBarbox: FunctionComponent<InfoBarboxProps> = ({
  bgColor = 'smGreen.500',
  icon,
  title,
  description,
  buttonLabel,
  hrefUrl = null,
  setStateUp = null,
  buttonOnClick = null,
  ...props
}) => {
  return (
    <VStack
      width='100%'
      height='100%'
      bg={bgColor}
      justifyContent='space-between'
      padding='1.2em 0.5em'
      spacing='1em'
      {...props}
    >
      <Box flex='0 0 auto'>{icon}</Box>
      <VStack alignItems='flex-start' flex='1' pl='1em' spacing='0px'>
        <Text fontWeight='700' fontSize='1.2em' color='smWhite.500'>
          {title}
        </Text>
        <Text fontFamily='var(--default-font-family)' fontSize='0.9em' color='smWhite.500'>
          {description}
        </Text>
      </VStack>
      {hrefUrl && (
        <Link href={hrefUrl} style={{ textDecoration: 'none' }}>
          <Button
            variant='speechmaticsWhite'
            mt='0px'
            data-qa={`button-${buttonLabel.toLowerCase()?.replace(' ', '-')}`}
            onClick={() => buttonOnClick?.()}
          >
            {buttonLabel}
          </Button>
        </Link>
      )}
      {setStateUp && (
        <Button variant='speechmaticsWhite' onClick={setStateUp}>
          {buttonLabel}
        </Button>
      )}
    </VStack>
  );
};

type ResponsiveStackProps = {
  breakPoint: boolean;
  bgColor?: string;
};

export const ResponsiveStack: React.FunctionComponent<ResponsiveStackProps> = ({
  breakPoint,
  bgColor = '#0000',
  children,
  ...props
}) => {
  return breakPoint ? (
    <HStack width='100%' bg={bgColor} justifyContent='space-between' alignItems='center' {...props}>
      {children}
    </HStack>
  ) : (
    <VStack width='100%' bg={bgColor} justifyContent='space-between' spacing='1em' {...props}>
      {children}
    </VStack>
  );
};

export const ViewUsageBox = () => (
  <InfoBarbox
    icon={<Image alt='Track Usage' src='/assets/temp_trackIcon.png' />}
    title='Track your usage'
    description='Usage is measured in minutes of audio processed'
    buttonLabel='View Usage'
    hrefUrl='/usage/'
  />
);

export const SmPanel: React.FunctionComponent<StackProps> = ({ children, ...props }) => (
  <VStack
    bg='smWhite.500'
    boxShadow='2px 2px 5px #5a5d5f15'
    p='1.5em'
    border='1px'
    borderColor='smBlack.180'
    rounded='lg'
    alignItems='flex-start'
    {...props}
  >
    {children}
  </VStack>
);

export const PageHeaderLabel: React.FunctionComponent = ({ children }) => (
  <Text fontWeight='700' fontSize='2em' mt={{ base: '0.7em', md: '2em' }}>
    {children}
  </Text>
);

export const PageIntroduction: React.FunctionComponent = ({ children }) => (
  <Text fontFamily='var(--default-font-family)' fontSize='1em' color='smNavy.400'>
    {children}
  </Text>
);

export const HeaderLabel: React.FunctionComponent<BoxProps> = ({
  children,
  fontSize = '1.4em',
  ...props
}) => (
  <Box fontWeight='700' fontSize={fontSize} mb='0.3em' {...props}>
    {children}
  </Box>
);

export const DescriptionLabel: React.FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box as='div' fontSize='1em' mb='1em' mt='1em' lineHeight='1.6em' color='smBlack.400' {...props}>
    {children}
  </Box>
);

export const Inline: React.FunctionComponent<TextProps & { dataQa?: string }> = ({
  children,
  dataQa,
  ...props
}) => (
  <Text as='span' data-qa={dataQa} {...props}>
    {children}
  </Text>
);

export const PageHeader = ({ title, description }: TitleProps) => {
  return (
    <Flex
      direction='column'
      width='100%'
      pb={12}
      gap={2}
      maxWidth='var(--panel-max-width)'
      className={styles.page_header}
    >
      <PageHeaderLabel>{title}</PageHeaderLabel>
      <PageIntroduction>{description}</PageIntroduction>
    </Flex>
  );
};

interface CopyButtonProps {
  copyContent?: string;
  afterCopiedContent?: ReactNode;
  onClick?: () => void;
  dataQa?: string;
  beforeCopiedContent?: React.ReactChild;
}

export const CopyButton = ({
  beforeCopiedContent = (<Copy />) as React.ReactChild,
  afterCopiedContent = <Check size={30} />,
  onClick,
  dataQa = 'copy-button',
  ...props
}: CopyButtonProps & ButtonProps) => {
  const [isTTOpen, setIsTTOpen] = useState(false);

  useEffect(() => {
    let st: number;

    if (isTTOpen)
      setTimeout(() => {
        setIsTTOpen(false);
      }, 3000);

    return () => clearTimeout(st);
  }, [isTTOpen]);

  return (
    <Button
      {...props}
      data-qa={dataQa}
      _focus={{ boxShadow: 'none' }}
      variant='speechmaticsIcon'
      onClick={() => {
        setIsTTOpen(true);
        onClick?.();
      }}
    >
      {isTTOpen ? afterCopiedContent : beforeCopiedContent}
    </Button>
  );
};

interface CopyTransciptButtonProps {
  copyContent: string;
  copyText: string;
  copiedText: string;
}

export const CopyTranscriptButton = ({
  copyContent,
  copyText,
  copiedText,
}: CopyTransciptButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    let st: NodeJS.Timeout;

    if (isClicked)
      st = setTimeout(() => {
        setIsClicked(false);
      }, 3000);

    return () => clearTimeout(st);
  }, [isClicked]);

  return (
    <Button
      data-qa='transcript-copy-button'
      variant='speechmatics'
      flex='1'
      leftIcon={isClicked ? <Check /> : <Copy />}
      fontSize='1em'
      onClick={() => {
        setIsClicked(true);
        navigator?.clipboard?.writeText(copyContent);
      }}
    >
      {isClicked ? copiedText : copyText}
    </Button>
  );
};

export interface DataGridComponentProps {
  data?: Array<unknown>;
  DataDisplayComponent: ElementType<{
    data?: Array<unknown>;
    isLoading: boolean;
  }>;
  isLoading: boolean;
  itemsPerPage?: number;
  onTrackUse?: () => void;
}
export const DataGridComponent: FunctionComponent<DataGridComponentProps> = ({
  data,
  DataDisplayComponent,
  isLoading,
  itemsPerPage = 5,
  onTrackUse = null,
}) => {
  const [page, setPage] = useState(0);
  const length = data?.length ?? 0;

  const pagesCount = Math.ceil(length / itemsPerPage);

  const onSelectPage = useCallback(
    (_page: number) => {
      setPage(_page - 1);
      onTrackUse?.();
    },
    [onTrackUse],
  );

  return (
    <>
      <DataDisplayComponent
        data={data?.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)}
        isLoading={isLoading}
      />

      {length > itemsPerPage && (
        <GridPagination
          onSelectPage={onSelectPage}
          pagesCountInitial={pagesCount}
          limits={{ inner: 1, outer: 1 }}
        />
      )}
    </>
  );
};

export type GridPaginationProps = {
  onSelectPage: (page: number) => void;
  pagesCountInitial: number;
  limits?: Limits;
};

export const GridPagination: ChakraComponent<'div', GridPaginationProps> = ({
  onSelectPage,
  pagesCountInitial,
  limits,
  ...props
}: GridPaginationProps) => {
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: pagesCountInitial,
    initialState: { currentPage: 1 },
    limits: limits,
  });

  const onPageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      onSelectPage?.(page);
    },
    [onSelectPage, setCurrentPage],
  );

  return (
    <Box width='100%' d='flex' justifyContent='flex-end' mt='1em' {...props}>
      <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={onPageChange}>
        <PaginationContainer>
          <PaginationPrevious
            color='smBlack.300'
            bg='smWhite.500'
            borderRadius='2px'
            fontSize='0.8em'
            fontWeight='300'
          >
            &lt; Previous
          </PaginationPrevious>
          <PaginationPageGroup>
            {pages.map((page: number) => (
              <PaginationPage
                fontSize='0.8em'
                p='1em'
                bg='smWhite.500'
                borderRadius='2px'
                _current={{
                  bg: 'smBlue.200',
                  color: 'smBlue.500',
                }}
                _focus={{ boxShadow: 'none' }}
                fontWeight='300'
                key={`pagination_page_${page}`}
                color='smBlack.300'
                page={page}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            color='smBlack.300'
            bg='smWhite.500'
            borderRadius='2px'
            fontSize='0.8em'
            fontWeight='300'
          >
            Next &gt;
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Box>
  );
};

export const pad = (n: number) => n.toString().padStart(2, '0');

export const ViewPricingBar: ComponentWithAs<'div', FlexProps> = (props) => {
  const breakVal = useBreakpointValue({
    xs: false,
    sm: true,
  });

  return (
    <Flex
      justifyContent='center'
      p='1em'
      alignItems='center'
      direction={breakVal ? 'row' : 'column'}
      {...props}
      {...{ [breakVal ? 'columnGap' : 'rowGap']: '1em' }}
    >
      <ViewPricingIcon />
      <Text fontWeight='700' fontSize='20px'>
        View our Pricing
      </Text>
      <Link
        href='https://www.speechmatics.com/our-technology/pricing'
        target='_blank'
        style={{ textDecoration: 'none' }}
      >
        <Button variant='speechmaticsOutline' mt='0em'>
          Pricing
        </Button>
      </Link>
    </Flex>
  );
};

export const GridSpinner = () => <Spinner size='sm' style={{ padding: '0px', marginTop: '2px' }} />;

type ConfirmRemoveModalProps = {
  onRemoveConfirm: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  returnFocusOnClose?: boolean;
};

export const ConfirmRemoveModal = ({
  isOpen,
  onClose,
  title,
  description,
  onRemoveConfirm,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  returnFocusOnClose = true,
}: TitleProps & ConfirmRemoveModalProps & BaseModalProps) => (
  <Modal returnFocusOnClose={returnFocusOnClose} isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent rounded='xl' borderRadius='2px'>
      <ModalCloseButton _focus={{ boxShadow: '' }} />
      <ModalBody>
        <Flex justifyContent='center' width='100%'>
          <Box mt='1em'>
            <ExclamationIconLarge />
          </Box>
        </Flex>
        <Box fontWeight='700' fontSize='1.5em' textAlign='center' px='1.5em' mt='0.5em'>
          {title}
        </Box>
        <Box
          fontWeight='300'
          fontSize='0.8em'
          textAlign='center'
          px='5em'
          color='smBlack.400'
          mt='1em'
        >
          {description}
        </Box>
      </ModalBody>
      <ModalFooter justifyContent='center'>
        <Flex alignItems='center'>
          <Button
            data-qa='button-confirm'
            variant='speechmaticsTranscribe'
            bg='smRed.500'
            _hover={{ bg: 'smRed.400' }}
            mr={3}
            py='1.1em'
            onClick={onRemoveConfirm}
          >
            {confirmLabel}
          </Button>
          <Button
            data-qa='button-cancel'
            variant='speechmaticsTranscribe'
            bg='smBlack.200'
            color='smBlack.400'
            py='1.1em'
            _hover={{ bg: 'smBlack.150' }}
            onClick={onClose}
          >
            {cancelLabel}
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const toast = createStandaloneToast({
  theme: {
    ...baseTheme,
    colors: {
      red: {
        500: 'var(--chakra-colors-smRed-500)',
      },
      green: {
        500: 'var(--chakra-colors-smGreen-500)',
      },
      blue: {
        500: 'var(--chakra-colors-smBlue-500)',
        300: 'var(--chakra-colors-smBlue-300)',
      },
    },
  },
});

export const errToast = (title: string, descr: ReactNode | Error) =>
  toast({
    title: title,
    description: descr instanceof Error ? JSON.stringify(descr) : descr,
    status: 'error',
    duration: 10000,
    position: 'bottom-right',
    isClosable: true,
    containerStyle: {
      fontFamily: 'var(--default-font-family)',
      fontWeight: 500,
    },
  });

export const errTopToast = (descr: string | object) =>
  toast({
    title: '',
    description: typeof descr === 'string' ? descr : JSON.stringify(descr),
    status: 'error',
    duration: 10000,
    position: 'top',
    isClosable: true,
    containerStyle: {
      fontFamily: 'var(--default-font-family)',
      fontWeight: 500,
    },
  });

export const positiveToast = (descr: string) =>
  toast({
    description: descr,
    status: 'success',
    duration: 10000,
    position: 'bottom-right',
    isClosable: true,
    containerStyle: {
      fontFamily: 'var(--default-font-family)',
      fontWeight: 500,
    },
  });

export const infoToast = (descr: string | object) =>
  toast({
    title: '',
    description: typeof descr === 'string' ? descr : JSON.stringify(descr),
    status: 'info',
    duration: 10000,
    position: 'bottom-right',
    isClosable: true,
    containerStyle: {
      fontFamily: 'var(--default-font-family)',
      fontWeight: 500,
    },
  });

type AttentionBarProps = {
  description: string;
  dataQa?: string;
  centered?: boolean;
};

export const AttentionBar = ({
  description,
  dataQa = 'attentionBar',
  centered = false,
}: AttentionBarProps) => (
  <HStack
    width='100%'
    bg='smRed.100'
    p='1em'
    spacing='1em'
    justifyContent={centered ? 'center' : ''}
  >
    <Box>
      <ExclamationIcon />
    </Box>
    <Text as='span' data-qa={dataQa} color='smRed.500' fontSize='0.95em' flex={centered ? '' : '1'}>
      {description}
    </Text>
  </HStack>
);

type ErrorBannerProps = {
  text?: string;
  content?: JSX.Element | string;
  alignment?: string;
  mt?: number | string;
};

export const ErrorBanner = ({
  text = '',
  content = '',
  alignment = 'center',
  mt = '2em',
}: ErrorBannerProps) => (
  <Flex
    rounded='xl'
    flexDir='column'
    width='100%'
    bg='smRed.100'
    p='1em'
    mt={mt}
    align={alignment}
    justify={alignment}
    alignItems={alignment}
  >
    <Flex>
      <Box>
        <ExclamationIcon width='1.5em' height='1.5em' />
      </Box>
      {content ? (
        <Box width='100%' color='smRed.500' ml='1em'>
          {content}
        </Box>
      ) : (
        <Text as='span' width='100%' color='smRed.500' fontSize='1em' ml='1em'>
          {text}
        </Text>
      )}
    </Flex>
  </Flex>
);

type PaymentWarningBannerProps = {
  accountState?: ContractState;
};

export function PaymentWarningBanner({ accountState }: PaymentWarningBannerProps) {
  return (
    <HStack zIndex={20} position='sticky' top='62px'>
      {accountState === 'past_due' && (
        <WarningBanner centered={true}>
          <>
            We’ve had trouble taking payment. Please{' '}
            <Link
              href='/manage-billing/#update_card'
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              update your card details
            </Link>{' '}
            to avoid disruptions to your account.{' '}
          </>
        </WarningBanner>
      )}
      {accountState === 'unpaid' && (
        <ErrorBanner
          mt='0'
          content={
            <>
              We’ve had trouble taking payment. Please{' '}
              <Link
                href='/manage-billing/#update_card'
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                update your card details
              </Link>{' '}
              to transcribe more files.{' '}
            </>
          }
        />
      )}
    </HStack>
  );
}

export function AccountErrorBox() {
  return (
    <Flex
      flexDir='column'
      width={['70%', '80%', '100%']}
      bg='smRed.100'
      p={['2em', '2em', '1em']}
      mt='2em'
      ml={[2, 2, 0]}
      align='center'
      justify='center'
      alignItems='center'
    >
      <VStack color='smRed.500' alignItems='flex-start'>
        <HStack>
          <Box>
            <ExclamationIcon width='1.5em' height='1.5em' />
          </Box>
          <Text fontFamily='var(--default-font-family)' fontSize='1em' ml='1em'>
            We were unable to get your account. Many of the app features will be disabled. To fix
            this problem, you should try:
          </Text>
        </HStack>
        <OrderedList alignItems='center' pl={12}>
          <ListItem>Refreshing the browser</ListItem>
          <ListItem>Logging out and logging back in</ListItem>
          <ListItem>
            Visiting our{' '}
            <span style={{ textDecorationLine: 'underline' }}>
              <Link href='https://docs.speechmatics.com/en/cloud/troubleshooting/'>
                troubleshooting
              </Link>
            </span>{' '}
            page
          </ListItem>
          <ListItem>
            Contacting{' '}
            <span style={{ textDecorationLine: 'underline' }}>
              <Link href='https://www.speechmatics.com/about-us/contact'>support</Link>
            </span>{' '}
            if all else fails
          </ListItem>
        </OrderedList>
      </VStack>
    </Flex>
  );
}

type CalendlyProps = {
  url: string;
  utm: Record<string, string | undefined>;
};

export const GetInTouchCalendlyBox = ({
  icon,
  title,
  description,
  url,
  utm,
  buttonLabel,
  ...stackProps
}: TitleProps & IconAndButtonProps & CalendlyProps) => {
  const breakVal =
    useBreakpointValue({
      xs: false,
      sm: true,
    }) || false;

  const email = useAccountEmail();
  const [isOpen, setIsOpen] = useState(false);

  const Container = breakVal ? HStack : VStack;
  const rootRef = useRef<HTMLElement>();
  useEffect(() => {
    rootRef.current = document.getElementById('__next') || undefined;
  }, []);

  return (
    <Container
      width='100%'
      bg='smNavy.500'
      rounded='xl'
      justifyContent='space-between'
      padding='1em 1.5em'
      {...stackProps}
    >
      <Box flex='0 0 auto'>{icon}</Box>
      <VStack alignItems='flex-start' flex='1' pl='1em' spacing='0px'>
        <Text fontWeight='700' fontSize='1.4em' color='smWhite.500'>
          {title}
        </Text>
        <Text fontFamily='var(--default-font-family)' fontSize='1em' color='smWhite.500' pb='0.5em'>
          {description}
        </Text>
      </VStack>
      <Button
        variant='speechmaticsWhite'
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {buttonLabel}
      </Button>
      {rootRef.current && (
        <PopupModal
          url={`${url}&${new URLSearchParams(
            // The above typings are strict enough, it doesn't matter if a value is undefined
            utm as Record<string, string>,
          ).toString()}`}
          pageSettings={{
            hideGdprBanner: true,
          }}
          prefill={{ email }}
          onModalClose={() => setIsOpen(false)}
          open={isOpen}
          rootElement={rootRef.current}
        />
      )}
    </Container>
  );
};

export const StartUsingApiBox = ({
  icon,
  title,
  description,
  buttonLabel,
  ...stackProps
}: IconAndButtonProps & TitleProps) => {
  const breakVal =
    useBreakpointValue({
      xs: false,
      sm: true,
    }) || false;

  const router = useRouter();

  const Container = breakVal ? HStack : VStack;

  return (
    <Container
      width='100%'
      bg='smNavy.500'
      justifyContent='space-between'
      padding='1em 1.5em'
      {...stackProps}
    >
      <Box flex='0 0 auto'>{icon}</Box>
      <VStack alignItems='flex-start' flex='1' pl='1em' spacing='0px'>
        <Text fontWeight='700' fontSize='1.4em' color='smWhite.500'>
          {title}
        </Text>
        <Text fontFamily='var(--default-font-family)' fontSize='1em' color='smWhite.500' pb='0.5em'>
          {description}
        </Text>
      </VStack>
      <Button variant='speechmaticsWhite' onClick={() => router.push('/learn')}>
        {buttonLabel}
      </Button>
    </Container>
  );
};

export const StatusPill = ({
  status,
  dataQa,
}: { status: keyof typeof statusColour; dataQa?: string }) => {
  const statusTooltipText = useMemo<string | null>(() => {
    switch (status) {
      case 'uploading':
        return 'The media is being uploaded.';
      case 'running':
        return 'The media is still being transcribed.';
      default:
        return null;
    }
  }, [status]);

  return (
    <Tooltip width='fit-content' label={statusTooltipText} hasArrow>
      <HStack data-qa={dataQa || 'list-job-status'}>
        {['uploading', 'running'].includes(status) ? (
          <Spinner size='xs' height='9px' width='9px' color={statusColour[status]} />
        ) : (
          <Box w={2} h={2} rounded='full' bgColor={statusColour[status]} />
        )}
        <Box color={statusColour[status]}>{capitalizeFirstLetter(status)}</Box>
      </HStack>
    </Tooltip>
  );
};

export const statusColour = {
  unknown: undefined,
  rejected: 'smRed.500',
  deleted: 'smBlack.250',
  done: 'smGreen.500',
  completed: 'smGreen.500',
  running: 'smOrange.400',
  expired: 'smRed.500',
  uploading: 'smBlue.400',
};
