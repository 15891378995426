import { GrowthBook, useFeatureIsOn as useFeatureIsOnGb } from '@growthbook/growthbook-react';
import { AppFeatures } from '../growthbook/app-features';
import { trackEvent } from './analytics';

const growthbook = new GrowthBook<AppFeatures>({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    if ('gtag' in window) {
      trackEvent('experiment_viewed', 'experiment', '', {
        experiment_id: experiment.key,
        variation_id: result.variationId,
      });
    }
  },
});

export default growthbook;

export const useFeatureIsOn = useFeatureIsOnGb<AppFeatures>;
