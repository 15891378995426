import { Box, Button, Spinner } from '@chakra-ui/react';
import { SpeechmaticsLogo } from '../components/icons-library';

import {
  useAuthenticate,
  useHandleRedirectAfterLogin,
  useHasMsalAccount,
  useLogin,
  useLoginInProgress,
} from '../utils/msal';

export default function Login() {
  useHandleRedirectAfterLogin();
  return (
    <div className='login_container'>
      <Box px='3em' maxWidth='500px'>
        <SpeechmaticsLogo width='100%' />
      </Box>
      <LoginSub />
    </div>
  );
}

export const LoginSub = ({ children }: { children?: React.ReactNode }) => {
  const inProgress = useLoginInProgress();
  const hasMsalAccount = useHasMsalAccount();
  const loginHandler = useLogin();
  const errorMessage = useAuthenticate();

  if (children && hasMsalAccount && inProgress === 'none') {
    return <>{children}</>;
  }
  if (
    inProgress === 'startup' ||
    inProgress === 'handleRedirect' ||
    (hasMsalAccount && inProgress === 'none')
  ) {
    return (
      <div className='login_text'>
        <Spinner />
        <p>
          {inProgress === 'startup'
            ? 'Starting...'
            : inProgress === 'handleRedirect'
            ? 'Redirecting...'
            : null}
        </p>
        {errorMessage && <Box>{errorMessage}</Box>}
      </div>
    );
  } else if (inProgress === 'login') {
    return (
      <div className='login_text'>
        <Spinner />
        <p>Logging in...</p>
        {errorMessage && <Box>{errorMessage}</Box>}
      </div>
    );
  } else if (inProgress === 'none' && !hasMsalAccount) {
    return (
      <div className='login_form'>
        {errorMessage && <Box>{errorMessage}</Box>}
        <Button
          variant='speechmatics'
          onClick={() => loginHandler(undefined, true)}
          data-qa='button-log-in'
        >
          Log in ➔
        </Button>
      </div>
    );
  } else return <></>;
};
