// lib
import { IconType } from '../lib/types';

const FiMoreHorizontal: IconType = ({ ...svgProps }) => (
  <svg
    fill='none'
    height='24'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    viewBox='0 0 24 24'
    width='24'
    {...svgProps}
  >
    <title>FiMoreHorizontal</title>
    <circle cx='12' cy='12' r='1' />
    <circle cx='19' cy='12' r='1' />
    <circle cx='5' cy='12' r='1' />
  </svg>
);

export default FiMoreHorizontal;
