import { useCallback, useEffect, useState } from 'react';
import { useRouter, NextRouter } from 'next/router';

import {
  useIsAuthenticated,
  useMsal,
  MsalProvider,
  useAccount,
  useMsalAuthentication,
} from '@azure/msal-react';
import {
  EventType,
  PublicClientApplication,
  type RedirectRequest,
  NavigationClient,
  LogLevel,
  BrowserAuthError,
  InteractionType,
  NavigationOptions,
} from '@azure/msal-browser';

import { accountStore } from '../account/account-store-context';
import { trackEvent, storeUtmData } from '../analytics';
import { ErrorCodes } from './error-codes';
import growthbook from '../growthbook';
import { callStore } from '../call-api';

// SHA256 hash reference code from Growthbook docs
async function sha256(str: string) {
  const buffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));
  const hashArray = Array.from(new Uint8Array(buffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
}

const salt = 'skmL-nqGgvFPU28cVTpj';
// Reaction to update Growthbook attributes once we get the user's JWT claims
const updateGrowthbookAttributes = async () => {
  const tokenClaims = msalInstance.getActiveAccount()?.idTokenClaims;
  if (tokenClaims) {
    // https://docs.growthbook.io/guide/GA4-google-analytics#using-gas-client-id
    const clientId = document?.cookie.match(/_ga=(.+?);/)?.[1].split('.').slice(-2).join('.');
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      id: tokenClaims.sub,
      email: await sha256(salt + tokenClaims.emails?.[0]?.toLowerCase()).catch(() =>
        console.warn('browser crypto not supported'),
      ),
      anonymous_id: clientId,
    });
  }
};

export function getMsalSubscriptionId() {
  return msalInstance.getActiveAccount()?.idTokenClaims?.sub;
}

let msalInstance: PublicClientApplication;

class MissingConfigError extends Error {
  constructor(key: string) {
    super(`Missing config: ${key}`);
  }
}

if (!process.env.RESET_PASSWORD_POLICY) throw new MissingConfigError('RESET_PASSWORD_POLICY');
if (!process.env.AUTHORITY_DOMAIN) throw new MissingConfigError('AUTHORITY_DOMAIN');
if (!process.env.AUTH_CLIENT_ID) throw new MissingConfigError('AUTH_CLIENT_ID');
if (!process.env.REDIRECT_URI) throw new MissingConfigError('REDIRECT_URI');
if (!process.env.POST_LOGOUT_REDIRECT_URI) throw new MissingConfigError('POST_LOGOUT_REDIRECT_URI');
if (!process.env.DEFAULT_B2C_SCOPE) throw new MissingConfigError('DEFAULT_B2C_SCOPE');
if (!process.env.SIGNIN_POLICY) throw new MissingConfigError('SIGNIN_POLICY');
if (!process.env.POLICY_DOMAIN) throw new MissingConfigError('POLICY_DOMAIN');
if (!process.env.SIGNUP_POLICY) throw new MissingConfigError('SIGNUP_POLICY');

const RESET_PASSWORD_POLICY = process.env.RESET_PASSWORD_POLICY;
const AUTHORITY_DOMAIN = process.env.AUTHORITY_DOMAIN;
const AUTH_CLIENT_ID = process.env.AUTH_CLIENT_ID;
const REDIRECT_URI = process.env.REDIRECT_URI;
const POST_LOGOUT_REDIRECT_URI = process.env.POST_LOGOUT_REDIRECT_URI;
const DEFAULT_B2C_SCOPE = process.env.DEFAULT_B2C_SCOPE;
const SIGNIN_POLICY = process.env.SIGNIN_POLICY;
const POLICY_DOMAIN = process.env.POLICY_DOMAIN;
const SIGNUP_POLICY = process.env.SIGNUP_POLICY;

export function initialiseMsal() {
  if (msalInstance) {
    return;
  }
  msalInstance = new PublicClientApplication({
    auth: {
      clientId: AUTH_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
      authority: SIGNIN_POLICY,
      knownAuthorities: [AUTHORITY_DOMAIN], // Mark your B2C tenant's domain as trusted.
      redirectUri: REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI, // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: number, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  });

  return msalInstance.initialize().then(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload &&
        'account' in event.payload
      ) {
        // console.log('account after login success', account)
        msalInstance.setActiveAccount(event.payload.account ?? null);
      }
    });
  });
}

const DEFAULT_SCOPES = [DEFAULT_B2C_SCOPE];

const defaultLoginRequest = {
  scopes: DEFAULT_SCOPES,
  authority: SIGNIN_POLICY,
};

export function msalLogout(inactive = false) {
  const account = msalInstance.getActiveAccount();
  accountStore.clear();
  sessionStorage.clear();
  if (account) {
    msalInstance.logoutRedirect({
      account,
      authority: account
        ? `https://${AUTHORITY_DOMAIN}/${POLICY_DOMAIN}/${account?.idTokenClaims?.acr}`
        : SIGNIN_POLICY,
      postLogoutRedirectUri: `${POST_LOGOUT_REDIRECT_URI}${inactive ? '#inactive' : '#logout'}`,
    });
  }
}

export function msalRefresh(): null | Promise<string> {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    msalLogout(true);
    return null;
  }

  return msalInstance
    .acquireTokenSilent({
      scopes: DEFAULT_SCOPES,
      account,
      authority: `https://${AUTHORITY_DOMAIN}/${POLICY_DOMAIN}/${account?.idTokenClaims?.acr}`,
    })
    .then((response) => {
      updateGrowthbookAttributes();
      return response.idToken;
    });
}

/**
 * A wrapper around MSAL useIsAuthenticated that returns false if the user doesn't have an active account
 */
export const useIsAuthenticatedAndHasAccount = () => {
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();
  // TODO: figure out why isAuthenticated can be true even when account is nullish
  return account ? isAuthenticated : false;
};

/**
 * If a user has been authenticated, but doesn't have an account:
 * - check with the server if they have an account
 * - if they don't, set up an account for them
 * @returns a boolean `isSettingUpAccount`
 */
export const useSetUpAccountFlow = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isSettingUpAccount, setIsSettingUpAccount] = useState(false);
  const [inFetchFlow, setInFetchFlow] = useState(false);
  const [error, setError] = useState<unknown>(null);

  /**
   * not reliable here as could be called with old state, probably need an swr mutation
   */
  // TODO: DEL-15399 improve error handling, don't access mobx store for error info, allow users to recover from 500 and 400 errors without refreshing
  if (
    !inFetchFlow &&
    !accountStore.account &&
    isAuthenticated &&
    !callStore.has500Error &&
    !error
  ) {
    setInFetchFlow(true);
    const idTokenClaims = msalInstance.getActiveAccount()?.idTokenClaims;

    const isSsoUser = !!(idTokenClaims && 'idp' in idTokenClaims && !!idTokenClaims.idp);
    accountStore
      .accountsFetchFlow(
        () => {
          setIsSettingUpAccount(true);
        },
        () => {
          setIsSettingUpAccount(false);
        },
        isSsoUser,
      )
      .then(() => {
        setInFetchFlow(false);
      })
      .catch((err) => {
        console.error('dashboard accountStore catch', err);
        setError(err);
        setInFetchFlow(false);
        setIsSettingUpAccount(false);
      });
  }

  return { isSettingUpAccount, errorSettingUpAccount: error };
};

export const useAccountEmail = () => {
  const account = useAccount();
  return account?.idTokenClaims?.emails?.[0];
};

export const useResetPassword = () => {
  const { instance } = useMsal();

  return useCallback(() => {
    instance
      .loginRedirect({
        ...defaultLoginRequest,
        authority: RESET_PASSWORD_POLICY,
        redirectUri: REDIRECT_URI,
      })
      .then((response) => console.log(response))
      .catch((error) => console.error(error));
  }, [instance]);
};
export const useLoginInProgress = () => {
  const { inProgress } = useMsal();
  return inProgress;
};

export const useHasMsalAccount = () => {
  const account = useAccount();
  return !!account;
};

export const useLogin = (force = false) => {
  const { instance } = useMsal();

  const loginHandler = useCallback(
    async (config: Partial<RedirectRequest> = {}, force = false) => {
      return instance
        .loginRedirect({
          ...defaultLoginRequest,
          // apps/adb2c may include a domainHint in the search string, forward this to msal if it's provided
          domainHint:
            new URLSearchParams(global.window?.location.search).get('domainHint') ?? undefined,
          ...config,
        })
        .catch((error) => {
          if (error instanceof BrowserAuthError && error.errorCode === 'interaction_in_progress') {
            // See conversation at https://teams.microsoft.com/l/message/19:daa4d6da0c4c452fa32456408ded42b8@thread.skype/1698254789489?tenantId=a9a1d4cf-a5d1-401f-9604-d068c8dcebd2&groupId=e46d4716-3300-410f-a9af-a191aa071360&parentMessageId=1698254789489&teamName=Engineering%20Team%20-%20O365&channelName=DevX%20Team%20-%20internal&createdTime=1698254789489&allowXTenantAccess=false
            console.error('unexpected interaction_in_progress', {
              'msal.interaction.status': sessionStorage.getItem('msal.interaction.status'),
              [`msal.${AUTH_CLIENT_ID}.urlHash`]: sessionStorage.getItem(
                `msal.${AUTH_CLIENT_ID}.urlHash`,
              ),
              force,
            });
            if (force) {
              sessionStorage.removeItem('msal.interaction.status');
              sessionStorage.removeItem(`msal.${AUTH_CLIENT_ID}.urlHash`);
              console.warn('Clearing MSAL cache and trying again');
              return instance.loginRedirect({
                ...defaultLoginRequest,
                // apps/adb2c may include a domainHint in the search string, forward this to msal if it's provided
                domainHint:
                  new URLSearchParams(global.window?.location.search).get('domainHint') ??
                  undefined,
                ...config,
              });
            }
          }
          console.log(error);
        });
    },
    [instance],
  );

  return loginHandler;
};

export const useHandleRedirectAfterLogin = () => {
  const { inProgress } = useMsal();
  const router = useRouter();
  const account = useAccount();

  const returnUrl = router.query.returnUrl?.toString();

  if (inProgress === 'none' && account) {
    const signupRedirect = sessionStorage.getItem('signupRedirect'); // check if user came from signup and hs redirect url
    sessionStorage.removeItem('signupRedirect'); // remove redirect url from session storage when consumed

    const isNewUser = account.idTokenClaims?.newUser;
    if (isNewUser) {
      router.push(returnUrl || signupRedirect || '/real-time-demo/');
    } else {
      router.push(returnUrl || signupRedirect || '/home/');
    }

    trackEvent('post_regular_login', 'B2C_Flow', 'User logged in change');
  }
};

export const useAuthenticate = () => {
  const loggedManualy = decodeURI(global.window?.location.hash).includes('logout');
  const loggedExpired = decodeURI(global.window?.location.hash).includes('inactive');

  const { error, login } = useMsalAuthentication(
    loggedManualy || loggedExpired ? InteractionType.None : InteractionType.Redirect,
    {
      ...defaultLoginRequest,
      // apps/adb2c may include a domainHint in the search string, forward this to msal if it's provided
      domainHint:
        new URLSearchParams(global.window?.location.search).get('domainHint') ?? undefined,
      onRedirectNavigate: () => {
        trackEvent('pre_regular_login', 'B2C_Flow', 'User logged in change');
      },
    },
  );

  const forgottenPassword = error?.message.includes(ErrorCodes.ForgottenPassword);

  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md#how-do-i-handle-the-forgot-password-flow-in-a-react-app
  useEffect(() => {
    if (forgottenPassword) {
      trackEvent('post_password_change', 'B2C_Flow', 'User coming back from password change');
      login(InteractionType.Redirect, {
        ...defaultLoginRequest,
        authority: RESET_PASSWORD_POLICY,
        state: 'postPasswordChange',
      });
    }
  }, [forgottenPassword, login]);

  const errorMessage = loggedExpired
    ? 'You were logged out due to an expired session.'
    : loggedManualy
    ? 'You were logged out.'
    : error?.message.includes(ErrorCodes.HintExpired)
    ? 'Your invitation token expired.'
    : error?.message.includes(ErrorCodes.UserExists)
    ? 'This user exists. Please log in using a password.'
    : forgottenPassword
    ? 'Forgotten password, redirecting...'
    : error
    ? error.message
    : null;

  return errorMessage;
};

export const useRedirectToSignup = () => {
  const router = useRouter();
  if (typeof window !== 'undefined') {
    storeUtmData();
    const redirect = router.query.redirect?.toString();

    if (redirect) {
      sessionStorage.setItem('signupRedirect', redirect); // save redirect url to session storage, used by useLogin
    }
  }
  return useMsalAuthentication(InteractionType.Redirect, {
    ...defaultLoginRequest,
    authority: SIGNUP_POLICY,
    extraQueryParameters: {
      option: 'signup',
    },
  });
};

/**
 * Taken from https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/nextjs-sample/src/utils/NavigationClient.js
 */
class CustomNavigationClient extends NavigationClient {
  router: NextRouter;

  constructor(router: NextRouter) {
    super();
    this.router = router;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useRouter hook provided by next.js to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url?.replace(window.location.origin, '');
    if (options.noHistory) {
      this.router?.replace(relativePath);
    } else {
      this.router.push(relativePath);
    }

    return false;
  }
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  // Taken from https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/nextjs-sample/pages/_app.js
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export { useIsAuthenticated };
