export const capitalizeFirstLetter = (str: string) =>
  `${str?.trim().charAt(0).toLocaleUpperCase()}${str?.trim().slice(1)}`;

export const pad = (n: number | string) => n.toString().padStart(2, '0');

export function humanFileSize(_bytes: number, si = false, dp = 1) {
  let bytes = _bytes;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

export function pluralize(
  value: number,
  singleForm: string,
  pluralForm: string,
  returnIfZero = false,
) {
  if (value === 1) return `${value} ${singleForm}`;
  if (value > 1) return `${value} ${pluralForm}`;
  if (value === 0) return returnIfZero;
}

export function lowerCaseNoSpace(value: string) {
  return value.toLocaleLowerCase()?.replace(/ /g, '-');
}

export function timeLeftFormat(secondsLeft: number) {
  return `${Math.floor(secondsLeft / 60)}m ${secondsLeft % 60}s`;
}

export const downloadHelper = (output: string, fileName: string, contentType: string) => {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(new Blob([output], { type: contentType }));
  a.download = fileName;
  a.click();
  try {
    document.removeChild(a);
  } catch (e) {
    console.log('temporary "a" element not removable');
  }
};

export const formatDuration = (duration: number): string => {
  const seconds = duration;
  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  }
  const minutes = seconds / 60;
  if (minutes < 60) {
    const roundedMinutes = Math.round(minutes);
    return `${roundedMinutes} minute${roundedMinutes !== 1 ? 's' : ''}`;
  }
  const hours = seconds / (60 * 60);
  if (hours < 24) {
    const roundedHours = Math.round(10 * hours) / 10;
    return `${roundedHours} hour${roundedHours !== 1 ? 's' : ''}`;
  }
  const days = seconds / (60 * 60 * 24);
  const roundedDays = Math.round(10 * days) / 10;
  return `${roundedDays} day${roundedDays !== 1 ? 's' : ''}`;
};
