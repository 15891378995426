import { ToastId } from '@chakra-ui/react';
import { makeAutoObservable } from 'mobx';
import { errToast } from '../components/common';
import { msalLogout, msalRefresh } from './msal';
import { accountStore } from './account/account-store-context';
import { PaymentMethod } from './account/account-types';

type RuntimeType = 'batch' | 'rt';

/**
 * TODO: DEL-15399 improve error handling (this should be a class extending error)
 */
export type RequestThrowType = {
  type: string;
  status: number;
  response: unknown;
  toastId: ToastId;
};

function getEndpointAPIURL() {
  const endpointUrl = process.env.ENDPOINT_API_URL;
  if (!endpointUrl) throw new Error('ENDPOINT_API_URL is empty');
  return endpointUrl.endsWith('v1') ? endpointUrl : endpointUrl.concat('/v1');
}

class CallStore {
  has500Error = false;
  hasConnectionError = false;

  constructor() {
    makeAutoObservable(this);
  }
}

export const callStore = new CallStore();

export const callPostAccounts = async (body?: Record<string, string>) => {
  return callRefresh(`${getEndpointAPIURL()}/accounts`, 'POST', body);
};

export const callGetAccounts = async () => {
  return callRefresh(
    `${getEndpointAPIURL()}/accounts`,
    'GET',
    undefined,
    undefined,
    'application/json',
    false,
    true,
  );
};

export const callPutAccounts = async (accountId: number, liteMode: boolean) => {
  return callRefresh(`${getEndpointAPIURL()}/accounts/${accountId}`, 'PUT', {
    lite_mode: liteMode,
  });
};

export const callGetUsage = async (contractId: number, projectId: number, dates: object) => {
  return callRefresh(
    `${getEndpointAPIURL()}/usage`,
    'GET',
    {},
    {
      contract_id: contractId,
      project_id: projectId,
      grouping: 'day',
      sort_order: 'asc',
      ...dates,
    },
  );
};

export const callRemoveApiKey = async (apiKeyId: string) => {
  return callRefresh(`${getEndpointAPIURL()}/api_keys/${apiKeyId}`, 'DELETE');
};

export const callGetSecrChargify = async (contractId: number) => {
  return callRefresh(`${getEndpointAPIURL()}/contracts/${contractId}/payment_token`, 'GET');
};

export const callPostRequestTokenChargify = async (contractId: number, chargifyToken: string) => {
  return callRefresh(`${getEndpointAPIURL()}/contracts/${contractId}/cards`, 'POST', {
    card_request_token: chargifyToken,
  });
};

export const callPostApiKey = async (name: string, projectId: number) => {
  return callRefresh(`${getEndpointAPIURL()}/api_keys`, 'POST', {
    project_id: projectId,
    name,
  });
};

export const callGetPayments = async () => {
  return callRefresh(`${getEndpointAPIURL()}/payments`, 'GET');
};

export const callGetPaymentMethods = async (contractId: number): Promise<PaymentMethod[]> => {
  return callRefresh(`${getEndpointAPIURL()}/contracts/${contractId}/cards`, 'GET');
};

export const callRemoveCard = async (contractId: number) => {
  return callRefresh(`${getEndpointAPIURL()}/contracts/${contractId}/cards`, 'DELETE');
};

export const callGetRuntimeSecret = async (ttl: number, type?: RuntimeType) => {
  return callRefresh(`${getEndpointAPIURL()}/api_keys${type ? `?type=${type}` : ''}`, 'POST', {
    ttl,
  });
};

export const callRefresh = async (
  apiEndpoint: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  body?: Record<string, string | number | boolean>,
  query?: { [key: string]: string | number } | null,
  contentType?: string,
  isBlob = false,
  cacheResponse = false,
) => {
  let authToken;
  try {
    authToken = await msalRefresh();
  } catch (e) {
    console.error('Error thrown when calling msalRefresh', e);
  }

  if (!authToken) {
    msalLogout(true);
    return null;
  }

  return call(authToken, apiEndpoint, method, body, query, contentType, isBlob, cacheResponse);
};

export const callDiscoveryRT = async (runtimeUrl: string) => {
  console.log('calling discovery RT', runtimeUrl);
  try {
    return call('', runtimeUrl, 'GET', null, null, null, false, false, true);
  } catch (error) {
    console.error('callDiscoveryRT failed', error);
    return Promise.reject(error);
  }
};

// TODO: DEL-15399 improve error handling
export const call = async (
  authToken: string,
  apiEndpoint: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  body: Record<string, string | number | boolean> | FormData | null = null,
  query: { [key: string]: string | number | boolean } | null = null,
  contentType: string | null = null,
  isBlob = false,
  cacheResponse = false,
  silence404 = false,
) => {
  // const authToken: string = await msalRefresh()
  const headers = new Headers();
  const bearer = `Bearer ${authToken}`;

  const useBODY = method.toLowerCase() !== 'get';
  const isPlain = contentType === 'text/plain';

  headers.append('Authorization', bearer);
  if (contentType !== 'multipart/form-data') {
    headers.append('Content-Type', contentType ? contentType : 'application/json');
    headers.append('cache-control', 'no-cache');
  }
  if (cacheResponse) {
    headers.append('cache-control', 'no-cache');
  }

  const options = {
    method: method,
    headers: headers,
    body: useBODY && body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
  };

  let endpoint = apiEndpoint;
  if (query) {
    const parsedUrl = new URL(apiEndpoint);
    const params = new URLSearchParams(parsedUrl.search);
    for (const key in query) {
      params.append(key, `${query[key]}`);
    }
    parsedUrl.search = params.toString();
    endpoint = parsedUrl.href;
  }

  return fetch(endpoint, options).then(
    async (response) => {
      console.log('fetch then', apiEndpoint, response);

      if (response.status === 401) {
        if (accountStore.runtimeURL && apiEndpoint.includes(accountStore.runtimeURL)) {
          throw { status: 'error', error: { type: 'runtime-auth' } };
        }
        console.log('error status 401, will logout');
        msalLogout(false);
        errToast('Authentication error', 'redirecting to login page...');
        return;
      }

      if (response.status !== 200 && response.status !== 201) {
        let resp: Record<string, string> | null = null;

        try {
          resp = await response.json();
        } catch (e) {
          console.warn('response is not json formattable');
        }

        if (response.status === 410 && resp?.detail === 'user deleted') {
          msalLogout(false);
          errToast('Logged in user has been deleted', 'redirecting to login page...');
        }

        console.error(`fetch error on ${apiEndpoint} occured, response ${JSON.stringify(resp)}`);

        if (response.status === 500) {
          callStore.has500Error = true;
          return;
        }

        const toastId = errToast(`A ${response.status} error has occurred`, resp?.detail ?? '');

        const throwObj: RequestThrowType = {
          type: 'request-error',
          status: response.status,
          response: resp,
          toastId: toastId || '', // Technically the toastId may not be defined, but that's only if Chakra didn't initialize, so this should be safe
        };

        throw throwObj;
      }

      if (response.body === null) {
        return null;
      }

      const contentLength = response.headers.get('Content-Length');
      if (contentLength !== null && Number(contentLength) === 0) {
        return null;
      }

      if (isBlob) {
        return response.blob();
      }

      return isPlain ? response.text() : response.json();
    },
    (error) => {
      console.error('fetch error', error);
      //only happens when something goes wrong with the function fetch not a specific response,
      // the responses should be cought in the following catch block on this promise
      // setTimeout(() => msalLogout(true), 1000);
      // errToast(`Redirecting to login page...`);
      if (!silence404) callStore.hasConnectionError = true;
      // TODO: DEL-15399 improve error handling
      throw error;
    },
  );
};
