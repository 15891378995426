import { getMsalSubscriptionId } from './msal';

export const GTM_TRACKING_ID = process.env.GTAG || '';
export const HOTJAR_ACCOUNT_ID = process.env.HOTJAR_ACCOUNT_ID || '';

export const trackPageview = (url: string) => {
  if (!window.gtag) return;

  console.log('trackPageview', url);
  try {
    window.gtag('config', GTM_TRACKING_ID, {
      user_id: getMsalSubscriptionId(),
      page_location: url,
    });
  } catch (err) {
    console.error('Failed sending metrics', err);
  }
};

export const trackEvent = (
  action: string,
  category = 'Action',
  label = '',
  value: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams = {},
) => {
  if (!window.gtag) return;

  try {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      ...value,
    });
  } catch (err) {
    console.error('Failed sending metrics', err);
  }
};

export const trackAction = (action: string, { value }: { value?: unknown } = {}) => {
  if (!window.gtag) return;

  try {
    window.gtag('event', action, {
      event_category: 'Action',
      value,
    });
  } catch (err) {
    console.error('Failed sending metrics', err);
  }
};

const cachedCallbacks = new Map<string, (values?: object) => void>();

export function generateTrackCallbackAction(eventAction: string, eventLabel: string) {
  if (cachedCallbacks.has(eventAction)) return cachedCallbacks.get(eventAction);

  const cb = (values: object) => trackEvent(eventAction, 'ACTION', eventLabel, values);

  cachedCallbacks.set(eventAction, cb);

  return cb;
}

const UTM_LOCAL_STORAGE_KEY = 'SM_UTM_TRACKING';

export function storeUtmData() {
  localStorage.setItem(UTM_LOCAL_STORAGE_KEY, document.URL);
}

export function getStoredUtmData() {
  return localStorage.getItem(UTM_LOCAL_STORAGE_KEY);
}

export function clearUtmData() {
  localStorage.removeItem(UTM_LOCAL_STORAGE_KEY);
}
