import React from 'react';
import styles from './app-container.module.css';

export function AppContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.all_container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
