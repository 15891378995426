import { action, makeObservable, observable } from 'mobx';
import { clearUtmData, getStoredUtmData, trackEvent } from '../analytics';
import { callGetAccounts, callPostAccounts } from '../call-api';
import { ApiKey, ContractState, PaymentMethod } from './account-types';

class AccountContext {
  _account: Account | null = null;
  isLoading = true;
  userHint = '';

  responseError = false;

  _testAccountState: ContractState = 'active';

  constructor() {
    makeObservable(this, {
      clear: action,
      _account: observable,
      assignServerState: action,
      isLoading: observable,
      userHint: observable,
      fetchServerState: action,
      responseError: observable,
    });
  }

  set account(account: Account | null) {
    this._account = account;
  }

  get account(): Account | null {
    return this._account;
  }

  clear() {
    this.account = null;
  }

  getApiKeys(): ApiKey[] | undefined {
    return this._account?.contracts
      .filter((con) => !!con)?.[0]
      ?.projects.filter((proj) => !!proj)?.[0]
      ?.api_keys?.slice()
      .sort((elA, elB) => new Date(elB.created_at).getTime() - new Date(elA.created_at).getTime());
  }

  get projectId(): number | undefined {
    return this._account?.contracts
      .filter((con) => !!con)?.[0]
      ?.projects.filter((proj) => !!proj)?.[0].project_id;
  }

  get contractId(): number | undefined {
    return this._account?.contracts.filter((con) => !!con)?.[0]?.contract_id;
  }

  /**
   * @deprecated INCIDENTS-34 DEL-15266 payment_method from the API is no longer being returned, so this will always return null
   */
  get paymentMethod(): PaymentMethod | null {
    return this._account?.contracts.filter((con) => !!con)?.[0]?.payment_method ?? null;
  }

  get hasPaymentMethod(): boolean {
    // HACK: INCIDENTS-34 As we can no longer receive payment_method from the API we check that their plan has over 10 hours, which indicates they are on a paid plan
    return (this.getUsageLimit('standard') || 0) > 10;
  }

  getUsageLimit(type: 'standard' | 'enhanced'): number | undefined {
    const dict = {
      standard: 'LIM_DUR_CUR_MON_STANDARD_SEC',
      enhanced: 'LIM_DUR_CUR_MON_ENHANCED_SEC',
    };

    const val = this._account?.contracts
      .filter((con) => !!con)?.[0]
      ?.usage_limits?.find((el) => el.name === dict[type])?.value;

    if (val === undefined) return undefined;

    return val / 3600;
  }

  async fetchServerState() {
    this.responseError = false;
    this.isLoading = true;

    return callGetAccounts()
      .then((jsonResp) => {
        if (jsonResp && 'accounts' in jsonResp && Array.isArray(jsonResp.accounts)) {
          this.assignServerState(jsonResp);
          this.isLoading = false;
        } else {
          throw new Error('callGetAccounts response malformed', { cause: jsonResp });
        }
      })
      .catch((err) => {
        // TODO: DEL-15399 store or throw this error so it can be displayed in an error component or boundary
        console.error('fetchServerState', err);
        this.responseError = true;
        this.isLoading = false;
      });
  }

  assignServerState(response: GetAccountsResponse | Account) {
    if ('account_id' in response) {
      this.account = response;
    } else {
      const accounts = response.accounts.filter((acc) => !!acc);
      if (accounts.length > 0) {
        this.account = accounts[0];
      } else {
        this.account = null;
      }
    }
    this.isLoading = false;
  }

  get accountState(): ContractState | undefined {
    return this._account?.contracts[0]?.state;
  }

  get accountType(): AccountType | undefined {
    return this._account?.account_type;
  }

  /**
   * Fetches account from management platform, if an account does not exist then creates one
   */
  async accountsFetchFlow(
    onSetupAccountStart: () => void,
    onSetupAccountEnd: (success: boolean) => void,
    ssoUser: boolean,
  ): Promise<void> {
    this.isLoading = true;
    this.responseError = false;
    return callGetAccounts()
      .then(async (jsonResp) => {
        if (!jsonResp) {
          throw new Error('No response from callGetAccounts', { cause: jsonResp });
        }
        if (!Array.isArray(jsonResp.accounts)) {
          throw new Error('Unexpected response from callGetAccounts, missing accounts', {
            cause: jsonResp,
          });
        }
        if (jsonResp.accounts.length === 0) {
          console.log(
            'no account on management platform, sending a request to create with POST /accounts',
          );
          onSetupAccountStart();
          try {
            const reqBody: Record<string, string> = {};

            const utmTracking = getStoredUtmData();
            if (utmTracking) {
              reqBody.utm_tracking = utmTracking;
            }

            // Extract the Google analytics ID from cookie using regex.
            //  Regex defines
            //  - Non capture group for key: "_ga="
            //  - Capture group for everything from "=" to ";" (exclusive)
            const googleAnalyticsId = document.cookie.match(/(?:_ga=)([^;]+)/)?.[1];
            if (googleAnalyticsId) {
              reqBody.google_analytics_id = googleAnalyticsId;
            }

            await callPostAccounts(reqBody);
          } catch (e) {
            onSetupAccountEnd(false);
            throw e;
          }

          onSetupAccountEnd(true);

          const action = ssoUser ? 'post_social_signup' : 'post_email_signup';
          trackEvent(action, 'B2C_Flow', 'New user sign in');
          clearUtmData();
          this.isLoading = false;
          return this.fetchServerState();
        } else if (jsonResp.accounts.length > 0) {
          clearUtmData();
          this.assignServerState(jsonResp);
          this.isLoading = false;
          return;
        }

        // We shouldn't hit this line
        throw new Error('Unexpected fork in accountsFetchFlow');
      })
      .catch((err) => {
        // TODO: DEL-15399 store or throw this error so it can be displayed in an error component or boundary
        this.responseError = true;
        this.isLoading = false;
        throw err;
      });
  }

  get runtimeURL(): string | undefined {
    return this._account?.contracts.filter((con) => !!con)?.[0]?.runtime_url;
  }

  get realtimeRuntimeURL(): string | undefined {
    const url = this._account?.contracts.filter((con) => !!con)?.[0]?.rt_runtime_url;
    if (!url) return;
    return url.endsWith('v2') || url.endsWith('v1') ? url : `${url}/v2`;
  }

  get realtimeDiscoveryUrl(): string | undefined {
    if (process.env.RT_DISCOVERY_OVERRIDE_URL) return process.env.RT_DISCOVERY_OVERRIDE_URL;

    const rtUrl = this.realtimeRuntimeURL;
    if (!rtUrl) return;

    if (rtUrl?.endsWith('v1')) {
      return rtUrl.replace('wss:', 'https:').replace('ws:', 'http:').concat('/discovery/features');
    } else if (rtUrl?.endsWith('v2')) {
      return (
        rtUrl
          .replace('wss:', 'https:')
          .replace('ws:', 'http:')
          // Still need to use /v1 route for discovery
          .replace('/v2', '/v1')
          .concat('/discovery/features')
      );
    } else {
      return rtUrl
        .replace('wss:', 'https:')
        .replace('ws:', 'http:')
        .concat('/v1/discovery/features');
    }
  }
}

export const accountStore = new AccountContext();

export type AccountType = 'free' | 'paid' | 'enterprise';

interface GetAccountsResponse {
  accounts: Account[];
}

interface Account {
  account_id: number;
  account_type: AccountType;
  contracts: Contract[];
  lite_mode: boolean;
}

interface Contract {
  contract_id: number;
  usage_limits: UsageLimit[];
  projects: Project[];
  runtime_url: string;
  rt_runtime_url: string;
  payment_method: PaymentMethod | null;
  state: ContractState;
}

interface UsageLimit {
  name: string;
  value: number;
}

interface Project {
  project_id: number;
  name: string;
  api_keys: ApiKey[];
}
