import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';

export interface CookieBot {
  accepted: boolean;
  declined: boolean;
  onaccept: () => void;
  ondecline: () => void;
}

declare global {
  interface Window {
    Cookiebot?: CookieBot;
  }
}

export type CookieConsentState = 'WaitingInput' | 'Accepted' | 'Rejected';

export class CookieConsentStore {
  consentState: CookieConsentState = 'WaitingInput';

  constructor() {
    makeObservable(this, {
      consentState: observable,
      setConsentState: action,
    });
  }

  setConsentState(state: CookieConsentState) {
    this.consentState = state;
    if (state === 'Accepted') {
      import('../utils/datadog').then((dataDogInit) => {
        dataDogInit.default();
      });
      window.gtag?.('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }
  }

  // Initialize store based on Cookiebot's state
  // set up callbacks for updating on change
  async initFromCookiebot() {
    const cookiebot = await getCookiebot();
    if (!cookiebot) {
      console.error('Failed to initialize cookie bot');
      return;
    }

    const onaccept = () => this.setConsentState('Accepted');
    const ondecline = () => this.setConsentState('Rejected');

    cookiebot.onaccept = onaccept.bind(this);
    cookiebot.ondecline = ondecline.bind(this);

    if (cookiebot.accepted) {
      this.setConsentState('Accepted');
    } else if (cookiebot.declined) {
      this.setConsentState('Rejected');
    }
  }
}

async function getCookiebot(): Promise<CookieBot | undefined> {
  const maxRetries = 100;
  for (let i = 0; i < maxRetries; i++) {
    const cookiebot = global.window?.Cookiebot;
    if (cookiebot) {
      return cookiebot;
    }
    await new Promise((res) => setTimeout(res, 500));
  }
}

export const cookieConsentStore = new CookieConsentStore();

const cookieConsentContext = createContext(cookieConsentStore);
export default cookieConsentContext;

export function useCookieConsentState(): CookieConsentState {
  return useContext(cookieConsentContext).consentState;
}
