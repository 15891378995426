import './theme-config.css';
import '@radix-ui/themes/styles.css';

import { ChakraProvider } from '@chakra-ui/react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import Head from 'next/head';
import { Router } from 'next/router';
import { useEffect } from 'react';
import { QueryParamProvider } from 'use-query-params';

import theme from '../static_data/theme';
import '../styles/animate.css';
import '../styles/components-styles.css';
import '../styles/dashboard.css';
import '../styles/fonts.css';
import '../styles/login.css';
import '../styles/main.css';
import '../styles/reset.css';
import { trackPageview } from '../utils/analytics';
import CookieConsentContext, { cookieConsentStore } from '../utils/cookie-consent-context';
import growthbook from '../utils/growthbook';
import { AuthProvider, initialiseMsal } from '../utils/msal';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { LoginSub } from './login';
import { AppProps } from 'next/app';
import NextAdapterPages from '../utils/NextAdapterPages';
import { AppContainer } from '../components/app-container';

Router.events.on('routeChangeComplete', (url) => {
  trackPageview(url);
});

initialiseMsal();

const UNAUTH_ROUTES = ['/login', '/signup', '/', '/version'];

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    cookieConsentStore.initFromCookiebot();
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <CookieConsentContext.Provider value={cookieConsentStore}>
        <ChakraProvider resetCSS={true} theme={theme}>
          <AuthProvider>
            <QueryParamProvider adapter={NextAdapterPages}>
              <Head>
                <title>Speechmatics Portal</title>
                <meta
                  name='viewport'
                  content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
                />
              </Head>
              <AppContainer>
                {UNAUTH_ROUTES.includes(router.route) ? (
                  <Component {...pageProps} />
                ) : (
                  <LoginSub>
                    <AuthenticatedTemplate>
                      <Component {...pageProps} />
                    </AuthenticatedTemplate>
                  </LoginSub>
                )}
              </AppContainer>
            </QueryParamProvider>
          </AuthProvider>
        </ChakraProvider>
      </CookieConsentContext.Provider>
    </GrowthBookProvider>
  );
}
