import { CSSObject, extendTheme, theme as baseTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const smTabStyle = {
  bg: 'smBlack.120',
  //also look for button[role="tab"]:not(:first-child)
  borderBottom: '1px solid var(--chakra-colors-smBlack-180)',
  borderTop: '1px solid var(--chakra-colors-smBlack-180)',
  borderLeft: '1px solid var(--chakra-colors-smBlack-180)',
  borderRight: '1px solid var(--chakra-colors-smBlack-180)',
  fontSize: '0.8em',
  px: '2.2em',
  fontFamily: 'var(--default-font-family)',
  color: 'smBlack.300',
  _selected: {
    color: 'smBlue.400',
    bg: 'white',
    borderTop: '2px solid #5398FC',
    borderLeft: '1px solid var(--chakra-colors-smBlack-180)',
    borderRight: '1px solid var(--chakra-colors-smBlack-180)',
    borderBottom: '1px solid #0000',
  },
  _focus: {
    boxShadow: 'none',
  },
} as CSSObject;

const breakpoints = createBreakpoints({
  xs: '22em', // 352px
  sm: '30em', // 480
  md: '48em', // 768
  lg: '62em', // 992
  xl: '80em', // 1280
  '2xl': '96em', // 1536
});

const theme = extendTheme({
  styles: {
    global: {
      '.js-focus-visible :focus:not([data-focus-visible-added])': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
  breakpoints,
  fonts: {
    heading: 'var(--default-font-family)',
    body: 'var(--default-font-family)',
  },
  colors: {
    smGreen: {
      50: '#FAFDFE',
      100: '#F2FAFB',
      140: '#CAF1F6',
      150: '#B5E9F0',
      200: '#9DDDE7',
      300: '#7DCEDC',
      350: '#3DB9CF',
      400: '#00A2C7',
      500: '#0797B9',
      600: '#107D98',
      700: '#0D3C48',
    },
    smBlue: {
      50: '#FAFDFE',
      100: '#F2FAFB',
      140: '#CAF1F6',
      150: '#B5E9F0',
      200: '#9DDDE7',
      300: '#7DCEDC',
      350: '#3DB9CF',
      400: '#00A2C7',
      500: '#0797B9',
      600: '#107D98',
      700: '#0D3C48',
    },
    smNavy: {
      100: '#F9F9F9', //dashboard background
      200: '#F0F0F0',
      250: '#E8E8E8',
      270: '#E0E0E0',
      280: '#D9D9D9',
      300: '#CECECE',
      350: '#BBBBBB',
      400: '#8D8D8D',
      450: '#838383',
      500: '#646464',
      700: '#202020',
    },
    smBlack: {
      80: '#fcfcfd',
      100: '#F4F6F9',
      120: '#f5f7fa',
      130: '#f0f2f4',
      150: '#E1E5E8',
      170: '#e7e9eb',
      180: '#E8EBED',
      200: '#D1D7D6',
      250: '#A8A0AB',
      300: '#78808B',
      400: '#5A5D5F',
      420: '#5E6673',
      500: '#000000',
      transparent: 'rgb(200,200,200,0.5)',
    },
    smRedGray: {
      400: '#BF9E9E',
      500: '#AD8585',
    },
    smRed: {
      50: '#FEF3F2',
      100: '#FAEFEF',
      400: '#D93D42',
      500: '#E5484D',
      600: '#E5484D',
      700: '#E5484D',
    },

    smPurple: {
      500: '#A64B82',
      700: '#5925DC',
    },
    smYellow: {
      500: '#F8CF38',
    },
    smOrange: {
      50: '#FFFAEB',
      150: '#CB6C4310',
      200: '#fcf2e8',
      400: '#E27919',
      500: '#CB6C43',
    },
    smWhite: {
      150: '#ffffff26',
      500: '#FFFFFF',
    },
  },
  components: {
    Accordion: {
      variants: {
        speechmatics: {
          root: {
            rounded: 'xl',
            border: '1px',
            borderColor: 'smBlack.200',
            boxShadow: '5px 5px 10px var(--box-shadow-colour)',
            display: 'inline-block',
          },
          button: {
            rounded: 'xl',
            bg: 'smBlack.80',
            p: 4,
          },
          panel: {
            maxWidth: 'fit-content',
          },
        },
        speechmatics_flat: {
          root: {
            display: 'inline-block',
          },
          button: {
            borderTop: '1px solid var(--chakra-colors-smBlack-180)',
            p: 4,
          },
          panel: {
            maxWidth: 'fit-content',
          },
        },
      },
    },
    Button: {
      variants: {
        speechmaticsIcon: {
          background: 'smBlack.transparent',
          border: '1px',
          borderColor: 'smBlack.200',
          width: '50px',
          color: 'smNavy.400',
          _hover: {
            bg: 'smNavy.400',
            _disabled: {
              bg: 'smBlack.200',
            },
            color: '#fff',
          },
        },
        speechmatics: {
          color: 'smWhite.500',
          bg: 'smBlue.500',
          rounded: 'xl',
          py: '1.5em',
          px: '2.5em',
          fontSize: '0.8em',
          fontFamily: 'var(--default-font-family)',
          _hover: {
            bg: 'smBlue.400',
            _disabled: {
              bg: 'smBlack.200',
            },
          },
          _focus: {
            boxShadow: 'none',
          },
          _active: {
            bg: 'smBlue.300',
          },
          _disabled: {
            bg: 'smBlack.250',
          },
        } as CSSObject,

        speechmaticsGreen: {
          color: 'smWhite.500',
          bg: 'smGreen.500',
          rounded: 'xl',
          py: '1.5em',
          px: '2.5em',
          _hover: {
            bg: 'smGreen.400',
            _disabled: {
              bg: 'smBlack.200',
            },
          },
          _focus: {
            boxShadow: 'none',
          },
          _active: {
            bg: 'smGreen.300',
          },
          _disabled: {
            bg: 'smBlack.300',
          },
        } as CSSObject,

        speechmaticsWhite: {
          color: 'smNavy.500',
          bg: 'smWhite.500',
          borderRadius: '2px',
          rounded: 'xl',
          py: '1.5em',
          px: '2em',
          fontSize: '0.8em',
          fontFamily: 'var(--default-font-family)',
          _hover: {
            bg: 'smBlack.200',
          },
          _focus: {
            boxShadow: 'none',
          },
          _active: {
            bg: 'smBlue.300',
          },
        } as CSSObject,

        speechmaticsOutline: {
          color: 'smBlue.500',
          bg: 'smWhite.500',
          rounded: 'xl',
          border: '2px solid',
          borderColor: 'smBlue.500',
          fontSize: '0.8em',
          fontFamily: 'var(--default-font-family)',
          _hover: {
            bg: 'smBlue.100',
          },
          _focus: {
            boxShadow: 'none',
          },
          _active: {
            bg: 'smBlue.300',
          },
        },

        speechmaticsSelect: {
          minW: '200px',
          justifyContent: 'space-between',
          color: 'smBlack.300',
          fontWeight: 'normal',
          fontSize: 'lg',
          border: '1px solid',
          rounded: 'xl',
          borderRadius: '2px',
          borderColor: 'smBlack.200',
          _hover: {
            borderColor: 'smBlue.500',
          },
          _active: {
            bg: '',
          },
          h: 12,
          bg: 'white',
          p: 2,
        } as CSSObject,

        speechmaticsTranscribe: {
          justifyContent: 'space-between',
          fontSize: 'sm',
          fontFamily: 'var(--default-font-family)',
          border: '2px solid',
          rounded: 'xl',
          borderRadius: '2px',
          borderColor: 'smBlue.300',
          color: 'white',
          bg: 'smBlue.500',
          _hover: {
            _disabled: {
              bg: 'smBlue.400',
            },
          },
        } as CSSObject,
      },
    },
    Input: {
      variants: {
        speechmatics: {
          ...baseTheme.components.Input.defaultProps,
          field: {
            py: '1.8em',
            borderRadius: '2px',
            border: '1px solid #cacad0',
          },
        },
      },
    },
    Radio: {
      variants: {
        smForm: {
          control: {
            borderColor: 'smBlack.200',
            borderWidth: '1px',
            w: '23px',
            h: '23px',
            _checked: {
              borderColor: 'smBlack.200',
              background: 'white',
              _before: {
                background: 'smNavy.400',
              },
              _hover: {
                borderColor: 'smBlack.200',
                background: 'smNavy.200',
              },
            },
            _hover: {
              borderColor: 'smBlack.200',
              background: 'smNavy.200',
            },
          },
          label: {
            color: 'smNavy.400',
          },
        },
      },
    },
    Checkbox: {
      variants: {
        default: {
          container: {
            py: 2,
            px: 3,
          },
          control: {
            borderColor: '#CECECE',
            borderWidth: '1px',
            borderRadius: '2px',
            width: '24px',
            height: '24px',
            background: 'white',
            _checked: {
              background: 'white',
              borderColor: '#CECECE',
              _hover: {
                background: 'smNavy.200',
                borderColor: '#CECECE',
              },
            },
            _hover: {
              background: 'smNavy.200',
            },
          },
          icon: {
            transition: 'none',
            color: 'smNavy.400',
            height: '13px',
            width: '13px',
          },
          label: {
            color: 'smNavy.400',
          },
        },
        multiselect: {
          container: {
            color: 'smBlack.400',
            _checked: {
              bg: 'smBlack.100',
            },
            _hover: {
              bg: 'smBlack.200',
            },
          },
          control: {
            border: '1px',
            borderColor: 'smBlack.200',
            bg: 'white',
            _checked: {
              bg: 'white',
              borderColor: 'smBlack.200',
            },
          },
          icon: {
            color: 'smGreen.500',
            bg: 'white',
            _checked: {
              bg: 'white',
            },
          },
        },
      },
    },

    Textarea: {
      variants: {
        outline: {
          borderColor: '#CECECE',
          borderRadius: 2,
          py: 4,
          px: 5,
          _placeholder: {
            color: 'smNavy.300',
          },
          _focus: {
            borderColor: 'smNavy.300',
            outline: 'none',
            boxShadow: 'none',
          },
        },
      },
    },

    NumberInput: {
      variants: {
        outline: {
          root: {
            borderColor: 'smBlack.200',
            boxShadow: 'none',
            _hover: {
              borderColor: 'smBlack.250',
            },
            _focus: {
              borderColor: 'smBlack.250',
            },
          },
          field: {
            borderRadius: 2,
            py: 7,
            _focus: {
              borderColor: 'smBlack.250',
              boxShadow: 'none',
            },
            _placeholder: {
              color: 'smNavy.300',
            },
            color: 'smNavy.400',
          },
          stepper: {
            borderLeft: 'none',
            color: 'smNavy.400',
            _active: {
              background: 'transparent',
              opacity: 0.5,
            },
          },
          stepperGroup: {
            // py: 1,
            pr: 4,
          },
        },
      },
    },

    Tabs: {
      variants: {
        speechmatics: {
          tab: smTabStyle,
          tabpanel: {
            bg: 'smWhite.500',
            padding: '1.5em',
            roundedBottom: 'xl',
            roundedTopRight: 'xl',
          } as CSSObject,
          tabpanels: {
            border: '1px solid var(--chakra-colors-smBlack-180)',
            boxShadow: '4px 4px 7px var(--box-shadow-colour)',
            roundedBottom: 'xl',
            roundedTopRight: 'xl',
          } as CSSObject,
        },
        speechmaticsCode: {
          tab: smTabStyle,
          tabpanel: {
            bg: 'smWhite.500',
            padding: '0em',
          } as CSSObject,
          tabpanels: {
            border: '1px solid var(--chakra-colors-smBlack-180)',
            boxShadow: '4px 4px 7px #5A5D5F15',
          } as CSSObject,
        },
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        },
      },
    },
    Switch: {
      variants: {
        speechmatics: {
          track: {
            bg: 'smBlack.130',
            _checked: {
              bg: 'smBlack.130',
            },
            border: '1px',
            borderColor: 'smBlack.200',
          },
          thumb: {
            bg: 'smBlack.400',
            _checked: {
              bg: 'smBlue.500',
            },
          },
        },
      },
    },
    CloseButton: {
      variants: {
        speechmatics: {
          _hover: {
            bg: 'smBlack.200',
          },
          _focus: {},
          _active: { bg: 'smBlack.300' },
          rounded: 'full',
          bg: 'smWhite.500',
          border: '2px solid',
          borderColor: 'smBlack.300',
          color: 'smBlack.300',
        },
      },
    },
    Menu: {
      variants: {
        speechmaticsDownload: {
          list: {
            color: 'smNavy.400',
            border: '1px solid',
            rounded: 'xl',
            shadow: 'lg',
            fontSize: 14,
            borderColor: 'smBlack.200',
            minW: '0px',
            maxW: '180px',
            p: 2,
            zIndex: '101',
          },
          item: {
            _focus: { color: 'smBlue.500' },
          },
        },
      },
    },
  },
});

export default theme;
